import { client as axios } from "./axiosClient";
import { catchServiceException, Constants } from "@socialcoach/services";
import * as DTO from "../interfaces/dtos";
import { AutostickerProgress } from "../interfaces/dtos";

const AUTOSTICKER_SERVICE = `${Constants.API_URL}/autosticker`;

export class AutostickerServices {
  @catchServiceException()
  static async getByPromptId(promptId: number): Promise<DTO.AutostickerConfig[]> {
    const { data } = await axios.get<DTO.AutostickerConfig[]>(`${AUTOSTICKER_SERVICE}/prompt/${promptId}`, {
      withCredentials: true,
    });

    return data;
  }

  @catchServiceException()
  static async save(configRequest: DTO.AutostickerConfigRequest): Promise<DTO.AutostickerConfig> {
    const { data } = await axios.post<DTO.AutostickerConfig>(`${AUTOSTICKER_SERVICE}/config`, configRequest, {
      withCredentials: true,
    });

    return data;
  }

  @catchServiceException()
  static async getProgress(configId: number): Promise<DTO.AutostickerProgress> {
    const { data } = await axios.get<DTO.AutostickerProgress>(`${AUTOSTICKER_SERVICE}/config/${configId}/progress`, {
      withCredentials: true,
    });

    return data;
  }
}
