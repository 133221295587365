import * as React from "react";
import { Draggable } from "react-beautiful-dnd";
import { createVideoThumbnailBlob } from "../../../../../utils";
import { Media } from "../../../../../interfaces";

import "./styles.scss";
import { MediaItemInfo } from "../MediaItemInfo";
import { useEffect, useMemo, useState } from "react";
import ReactPlayer from "react-player";

interface PromptMediaItemProps {
  src: Media;
  isForReel: boolean;
  hasAutosticker: boolean;
  id: number;
  index: number;
  total: number;
  uploadProgress?: number;

  handleClose: (id: number) => void;
  handleCrop: (id: number) => void;
  handleAutosticker: (id: number) => void;
  handleAutostickerProgress: (id: number) => void;
}

const PromptMediaItemFC: React.FC<PromptMediaItemProps> = ({
  src,
  isForReel,
  hasAutosticker,
  id,
  index,
  uploadProgress,
  handleClose,
  handleCrop,
  handleAutosticker,
  handleAutostickerProgress,
}: PromptMediaItemProps) => {
  const [thumbnail, setThumbnail] = React.useState<string | undefined>();
  const [naturalWidth, setNaturalWidth] = useState<number>();
  const [naturalHeight, setNaturalHeight] = useState<number>();

  const [playWhenReady, setPlayWhenReady] = useState(false);
  const [mediaPlaying, setMediaPlaying] = useState(false);
  const [mediaReady, setMediaReady] = useState(false);

  useEffect(() => {
    if (playWhenReady && mediaReady) {
      setMediaPlaying(true);
    }
  }, [playWhenReady, mediaReady]);

  const mediaStatus = useMemo(() => {
    if (uploadProgress && uploadProgress > 0 && uploadProgress < 1) {
      return `Uploading... ${Math.round(uploadProgress * 100)}% complete`;
    } else if (!thumbnail) {
      if (src.id > 0) return "Loading...";
      else return "Processing...";
    } else {
      return undefined;
    }
  }, [uploadProgress, thumbnail, src.id]);

  React.useEffect(() => {
    if (src && src.uri) {
      const urlPromise =
        src.category === "IMAGE" ? Promise.resolve(src.uri) : createVideoThumbnailBlob(src.downloadLink!);
      urlPromise.then(url => {
        setThumbnail(url);
      });
    }
  }, [src]);

  const thumbnailComponent = () => {
    return (
      <img
        className={"media-thumbnail"}
        src={thumbnail}
        alt={"thumbnail"}
        onLoad={img => {
          setNaturalWidth(img.currentTarget.naturalWidth);
          setNaturalHeight(img.currentTarget.naturalHeight);
        }}
      />
    );
  };

  const hasErrors = useMemo(() => {
    if (!thumbnail) return false;

    if (!src.validAspectRatio) return true;

    return !!(src.errors && Object.values(src.errors).some(error => error));
  }, [thumbnail, src.validAspectRatio, src.errors]);

  return (
    <>
      <Draggable key={id} draggableId={`media-item-${id}`} index={index}>
        {(provided: any) => (
          <div
            ref={provided.innerRef}
            className={`draggable-media-item-wrapper${hasErrors ? " withError" : ""}`}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className={"media-wrapper"}>
              {!thumbnail && <div className={"ui placeholder square media-placeholder"} />}
              {thumbnail && src.category === "IMAGE" && thumbnailComponent()}
              {thumbnail && src.category === "VIDEO" && (
                <ReactPlayer
                  url={src.uri}
                  light={thumbnailComponent()}
                  width={"200px"}
                  height={"auto"}
                  controls
                  playsinline
                  playing={mediaPlaying}
                  onClickPreview={() => {
                    setPlayWhenReady(true);
                  }}
                  onReady={() => {
                    setMediaReady(true);
                  }}
                  onPause={() => setMediaPlaying(false)}
                />
              )}
            </div>

            <MediaItemInfo
              item={src}
              hasAutosticker={hasAutosticker}
              isForReel={isForReel}
              mediaStatus={mediaStatus}
              naturalWidth={naturalWidth}
              naturalHeight={naturalHeight}
              handleRemoveMedia={handleClose}
              handleCrop={handleCrop}
              handleAutosticker={handleAutosticker}
              handleAutostickerProgress={handleAutostickerProgress}
            />
          </div>
        )}
      </Draggable>
    </>
  );
};

export const PromptMediaItem = PromptMediaItemFC;
