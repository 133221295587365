import * as React from "react";
import { Modal, Button } from "semantic-ui-react";

import "react-image-crop/dist/ReactCrop.css";
import "./styles.scss";
import { useEffect, useMemo, useState } from "react";
import * as DTO from "../../../../../interfaces";
import { AutostickerServices } from "../../../../../services/AutostickerServices";
import { useInterval } from "../../../../../containers/Players/RecordVideo/hooks";

interface OwnProps {
  storedConfig: DTO.AutostickerConfig | undefined;
  open: boolean;
  handleEdit: () => void;
  handleClose: () => void;
}

type Props = OwnProps;

const AutostickerProgressModalFC: React.FC<Props> = ({ open, storedConfig, handleClose, handleEdit }) => {
  const [loading, setLoading] = useState(false);
  const [needsReload, setNeedsReload] = useState(true);
  const [configProgress, setConfigProgress] = useState<DTO.AutostickerProgress>();

  useEffect(() => {
    if (open) {
      setNeedsReload(true);
    }
  }, [open]);

  const reloadProgress = async () => {
    if (open && storedConfig?.id) {
      setLoading(true);
      const result = await AutostickerServices.getProgress(storedConfig?.id);
      if (result.requests?.some(r => r.status === "PENDING")) setNeedsReload(true);
      else setNeedsReload(false);
      setConfigProgress(result);
      setLoading(false);
    } else {
      setConfigProgress(undefined);
    }
  };

  useInterval(
    () => {
      reloadProgress();
    },
    needsReload ? 1000 : undefined
  );

  const onClose = () => {
    setConfigProgress(undefined);
    handleClose();
  };

  const onEdit = () => {
    setConfigProgress(undefined);
    handleEdit();
  };

  const overallStatus: String = useMemo(() => {
    if (configProgress === undefined) {
      return "Loading...";
    }
    const totalCount = configProgress?.requests?.length || 0;
    const completeCount = configProgress?.requests?.filter(i => i.status === "COMPLETE")?.length || 0;
    if (totalCount === 0) {
      return "No custom content found";
    } else if (completeCount < totalCount) {
      return `Updating players' prompts (${completeCount} of ${totalCount} complete))`;
    } else {
      return `Prompts are ready with the latest changes! Click an image below to edit that player's sticker`;
    }
  }, [configProgress]);

  return (
    <Modal closeIcon className={"autosticker-progress-modal"} open={open} onClose={onClose}>
      <Modal.Header>Auto-Sticker Results</Modal.Header>
      <Modal.Content>
        <div className={"autosticker-info"}>
          <label>
            <b>Status:</b> {overallStatus}
          </label>
        </div>
        <div className={"autosticker-requests"}>
          {configProgress?.requests?.map(request => {
            return (
              <div key={`autosticker-request-${request.id}`} className={"autosticker-request-container"}>
                <div className={"autosticker-request-media"}>
                  {request.resultUri && (
                    <img
                      src={request.resultUri}
                      alt={"request-media"}
                      onClick={() => {
                        window.open(`/coach-players/${request.playerId}`, "_blank");
                      }}
                    />
                  )}
                  {!request.resultUri && <div className={"ui placeholder square media-placeholder"} />}
                </div>
              </div>
            );
          })}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button data-elm-id={`autostickerModalCancelBtn`} onClick={onClose} className={"rounded"} secondary>
          Close
        </Button>
        <Button data-elm-id={`autostickerModalEditBtn`} onClick={onEdit} className={"rounded"} secondary>
          Edit Configuration
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const AutostickerProgressModal = AutostickerProgressModalFC;
