import * as React from "react";
import { Formik } from "formik";
import { RouteComponentProps, withRouter } from "react-router";
import { get } from "lodash";

import { Prompt, Media, CoachSettings, PromptType } from "../../../../../interfaces";
import { PromptComponent } from "../../../../../components";
import { SettingsServices } from "../../../../../services";
import { URLS, mediaElement } from "../../../../../utils";

import { validationSchema } from "./validations";
export interface OwnProps {
  /**
   * Prompt Info
   */
  prompt?: Prompt;
  /**
   * Boolean to indicate if the form is editable (just some fields)
   */
  modeEditable: boolean;
  disabled: boolean;
  pageErrors?: string[];

  coachId: string;
  updatingHandler: (values: any) => void;
  onContentloading?: (value: boolean) => void;
}

type Props = OwnProps & RouteComponentProps;

const IntegratedPrompt: React.FC<Props> = ({
  modeEditable,
  disabled,
  prompt,
  updatingHandler,
  onContentloading,
  pageErrors,
  history,
  location,
  coachId,
}) => {
  const [attachedLoading, setAttachedLoading] = React.useState(false);
  const [videoLoading, setVideoLoading] = React.useState(false);
  const [showHashtagToggle, setShowHashtagToggle] = React.useState(false);

  React.useEffect(() => {
    if (onContentloading) {
      onContentloading(attachedLoading || videoLoading);
    }
  }, [attachedLoading, videoLoading, onContentloading]);

  React.useEffect(() => {
    const getSettings = async () => {
      SettingsServices.getAppSettingsByCoachId(coachId).then(response => {
        const hashtagSettingStatus =
          response.settings.find(s => s.appSettingId === CoachSettings.PLAYER_HASHTAG)?.status || "ACTIVE";
        setShowHashtagToggle(hashtagSettingStatus === "ACTIVE");
      });
    };

    if (coachId) {
      getSettings();
    }

    getSettings();
  }, [coachId]);

  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={true}
        validateOnMount={prompt?.promptId ? true : false}
        initialValues={{
          promptId: get(prompt, "promptId", null),
          title: get(prompt, "title", ""),
          message: prompt?.message,
          hashtags: get(prompt, "hashtags", ""),
          allowShortLink: get(prompt, "allowShortLink", true),
          allowComplianceDisclaimer: get(prompt, "allowComplianceDisclaimer", true),
          allowHashtags: get(prompt, "allowHashtags", true),
          richMessage: get(prompt, "richMessage", get(prompt, "message", "")),
          media: get(prompt, "mediaMessage.media", []),
          mediaMessage: get(prompt, "mediaMessage", {}),
          post: get(prompt, "post", null),
          withMediaForPost: get(prompt, "post.media.length", 0),
          withMessage: get(prompt, "message", "") + get(prompt, "hashtags", ""),
          status: get(prompt, "status", "DRAFT"),
          categories: get(
            prompt,
            "categories",
            location.state && location.state["category"] ? [location.state["category"]] : []
          ),
          type: get(prompt, "type", "FEED"),
        }}
        validationSchema={validationSchema}
        onSubmit={async () => {
          // Action removed because the client wants
          // to use TitleSection action to save the prompt
        }}
      >
        {({ values, errors, isSubmitting, handleChange, setFieldValue, handleSubmit }) => {
          updatingHandler(values);

          return (
            <PromptComponent
              messages={{
                error: pageErrors && pageErrors.length > 0 ? pageErrors : (Object.values(errors) as string[]),
              }}
              handleCancel={() => {
                history.push(URLS.coach.library);
              }}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleUploadVideo={handleSubmit}
              handleSetFieldValue={setFieldValue}
              handleMessageVideoUploaded={(url, extension) => {
                setVideoLoading(false);
                setFieldValue("media", [mediaElement(url, extension, "VIDEO")]);
              }}
              handleMessageVideoPreview={(url, extension) => {
                setVideoLoading(true);
                setFieldValue("media", [mediaElement(url, extension, "VIDEO")]);
              }}
              handleAttachedFileUploadStarted={() => setAttachedLoading(true)}
              handleAttachedFileUploadFinished={() => setAttachedLoading(false)}
              handleMediaUpdated={(newMedia: Media[]) => {
                setFieldValue("withMediaForPost", newMedia.length);
                setFieldValue("post", {
                  media: newMedia.map((item, sortOrder) => {
                    const newMediaObj = {
                      ...item,
                      sortOrder,
                    };
                    return newMediaObj;
                  }),
                  mediaGroupId: get(values.post, "mediaGroupId", null),
                  type:
                    values.type === PromptType.REELS
                      ? PromptType.REELS
                      : newMedia.length > 1
                      ? "MIX"
                      : newMedia[0] && newMedia[0].category,
                });
              }}
              showHashtagToggle={showHashtagToggle}
              disabled={disabled}
              modeEditable={modeEditable}
              values={values}
              isSubmitting={isSubmitting}
            />
          );
        }}
      </Formik>
    </div>
  );
};

export const PromptForm = withRouter(IntegratedPrompt);
