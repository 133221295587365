import * as React from "react";
import { PromptMediaItem } from "../PromptMediaItem";
import { AutostickerConfig, Media, UploadProgressMap } from "../../../../../interfaces";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

import "./styles.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EditImageModal } from "../../../../EditImageModal";
import { AutostickerModal } from "../AutostickerModal";
import { mediaEditedFirebaseUrl, saveElementToFirebase } from "../../../../../utils";
import { ConfigContext } from "../../../../../contexts/appContexts";
import { AppContext } from "../../../../../providers";
import { get } from "lodash";
import { AutostickerServices } from "../../../../../services/AutostickerServices";
import { AutostickerProgressModal } from "../AutostickerProgressModal";

interface PromptMediaListProps {
  onReordered?: any;
  media: Media[];
  isForReel: boolean;
  promptId: number;
  uploadProgress: UploadProgressMap;
  handleClose: (id: number) => void;
  handleUpdate: (id: number, url: string) => void;
  onDragEnd: (result: any) => void;
}

const validArray = (arr: any[]): boolean => {
  return Boolean(arr && arr.length && arr.length > 0);
};

const PromptMediaListFC: React.FC<PromptMediaListProps> = ({
  // onReordered,
  media,
  isForReel,
  promptId,
  uploadProgress,
  handleClose,
  handleUpdate,
  onDragEnd,
}: PromptMediaListProps) => {
  const { firebase } = React.useContext(ConfigContext);
  const { userContext } = React.useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");
  const [mediaModified, setMediaModified] = useState(false);

  const [cropMedia, setCropMedia] = useState<Media>();
  const [autostickerMedia, setAutostickerMedia] = useState<Media>();
  const [autostickerProgressMedia, setAutostickerProgressMedia] = useState<Media>();
  const [currentAutostickerConfigs, setCurrentAutostickerConfigs] = useState<AutostickerConfig[]>([]);

  const refreshConfigs = async () => {
    if (promptId) {
      const configs = await AutostickerServices.getByPromptId(promptId);
      setCurrentAutostickerConfigs(configs);
    }
  };

  useEffect(() => {
    refreshConfigs();
  }, [promptId]);

  const getConfig = useCallback(
    (media: Media | undefined) => {
      if (media) {
        return currentAutostickerConfigs.find(c => c.media?.id && c.media?.id === media.id);
      }
      return undefined;
    },
    [currentAutostickerConfigs]
  );

  const storedConfig: AutostickerConfig | undefined = useMemo(() => {
    return getConfig(autostickerMedia);
  }, [autostickerMedia, getConfig]);

  const storedProgressConfig: AutostickerConfig | undefined = useMemo(() => {
    return getConfig(autostickerProgressMedia);
  }, [autostickerProgressMedia, getConfig]);

  const uploadEditedImage = async (id: number, blob: Blob) => {
    setMediaModified(true);
    const timestamp = new Date().getTime();
    const url = await saveElementToFirebase(
      firebase,
      mediaEditedFirebaseUrl(coachId),
      blob,
      "editedPrompt" + id + timestamp
    );
    handleUpdate(id, url);
  };

  const openAutostickerModal = (id: number) => {
    if (id <= 0 || mediaModified) {
      alert("Please save the prompt before configuring Autosticker");
    } else {
      setAutostickerMedia(media.find((m: Media) => m.id === id));
    }
  };

  const openAutostickerProgressModal = (mediaId: number) => {
    if (mediaId <= 0 || mediaModified) {
      alert("Please save the prompt before configuring Autosticker");
    } else {
      setAutostickerProgressMedia(media.find((m: Media) => m.id === mediaId));
    }
  };

  const hasAutosticker = (item: Media) => {
    return !!item.id && currentAutostickerConfigs.some(c => c.media?.id == item.id);
  };

  return (
    <div className={"prompt-media-container"}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable key={"droppable_media"} droppableId={`media`} direction="vertical">
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {validArray(media) &&
                media.map((item: Media, i: number) => (
                  <PromptMediaItem
                    total={media.length}
                    src={item}
                    isForReel={isForReel}
                    hasAutosticker={hasAutosticker(item)}
                    key={"draggable_media_item_" + item.id}
                    id={item.id}
                    index={i}
                    handleClose={handleClose}
                    handleCrop={id => setCropMedia(media.find((m: Media) => m.id === id))}
                    handleAutosticker={id => openAutostickerModal(id)}
                    handleAutostickerProgress={id => openAutostickerProgressModal(id)}
                    uploadProgress={uploadProgress[item.id] && uploadProgress[item.id].progress}
                  />
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <EditImageModal
        handleSave={(_, blob) => {
          uploadEditedImage(cropMedia!!.id, blob);
          setCropMedia(undefined);
        }}
        open={!!cropMedia}
        type={cropMedia?.category}
        src={cropMedia?.uri}
        handleClose={() => setCropMedia(undefined)}
      />

      <AutostickerModal
        promptId={promptId}
        media={autostickerMedia}
        storedConfig={storedConfig}
        open={!!autostickerMedia}
        handleClose={() => setAutostickerMedia(undefined)}
        handleSave={newConfig => {
          setCurrentAutostickerConfigs(cList => [...cList.filter(c => newConfig.media?.id !== c.media?.id), newConfig]);
        }}
      />

      <AutostickerProgressModal
        open={!!storedProgressConfig}
        storedConfig={storedProgressConfig}
        handleClose={() => setAutostickerProgressMedia(undefined)}
        handleEdit={() => {
          setAutostickerMedia(autostickerProgressMedia);
          setAutostickerProgressMedia(undefined);
        }}
      />
    </div>
  );
};

export const PromptMediaList = PromptMediaListFC;
