import { defineMessages } from "react-intl";

export enum PromptType {
  welcomePrompt = "PromptType.welcomePrompt",
  deliveryLabel = "PromptType.deliveryLabel",
  randomDelivery = "PromptType.randomDelivery",
  specificDate = "PromptType.specificDate",
  library = "PromptType.library",
  promptTitle = "PromptType.promptTitle",
  promptMessage = "PromptType.promptMessage",
  videoMessage = "PromptType.videoMessage",
  attachDesc = "PromptType.attachDesc",
  categoryTitle = "PromptType.categoryTitle",
  datePlaceholder = "PromptType.datePlaceholder",
  timePlaceholder = "PromptType.timePlaceholder",
  titlePlaceholder = "PromptType.titlePlaceholder",
  messagePlaceholder = "PromptType.messagePlaceholder",
  uploadFilePlaceholder = "PromptType.uploadFilePlaceholder",
  uploadVideoPlaceholder = "PromptType.uploadVideoPlaceholder",
  categoryPlaceholder = "PromptType.categoryPlaceholder",

  saveBtn = "PromptType.saveBtn",
  videoBtn = "PromptType.videoBtn",
  uploadBtn = "PromptType.uploadBtn",
  browseBtn = "PromptType.browseBtn",
  cancelBtn = "PromptType.cancel",
  enableBtn = "PromptType.enable",
  addCategoryBtn = "PromptType.addCategory",

  categoryInfo = "PromptType.categoryInfo",
  campaignCategoryInfo = "PromptType.campaignCategoryInfo",
  categoryNotSaved = "PromptType.categoryNotSaved",
}

export const en = {
  [PromptType.datePlaceholder]: "Select a date",
  [PromptType.timePlaceholder]: "Select a time",
  [PromptType.deliveryLabel]: "Delivery mode",
  [PromptType.welcomePrompt]: "Welcome Prompt",
  [PromptType.randomDelivery]: "Random Delivery Date",
  [PromptType.specificDate]: "Specific Date",
  [PromptType.library]: "Library",

  [PromptType.promptTitle]: "Prompt Title",
  [PromptType.promptMessage]: "Prompt Message",
  [PromptType.videoMessage]: "Instructional Video (optional)",
  [PromptType.titlePlaceholder]: "Enter title",
  [PromptType.messagePlaceholder]: "Write Message",
  [PromptType.uploadFilePlaceholder]: "Select a file",
  [PromptType.uploadVideoPlaceholder]: "Select a video",
  [PromptType.categoryPlaceholder]: "Add Category",
  [PromptType.attachDesc]: "Image/Video for Post",
  [PromptType.videoBtn]: "Add your Video Message",
  [PromptType.uploadBtn]: "Upload",
  [PromptType.browseBtn]: "Browse",
  [PromptType.saveBtn]: "Done",
  [PromptType.cancelBtn]: "Cancel",
  [PromptType.enableBtn]: "Publish",
  [PromptType.addCategoryBtn]: "Add Category",
  [PromptType.categoryTitle]: "Categories",

  [PromptType.campaignCategoryInfo]: "Assign a category to your campaign to keep your campaigns organized.",
  [PromptType.categoryInfo]: "Assign a category to your prompt to keep your prompts organized within the Library",
  [PromptType.categoryNotSaved]: 'Please click "Add Category" to save your category',
};

export const descriptors = defineMessages({
  [PromptType.categoryNotSaved]: {
    id: PromptType.categoryNotSaved,
    defaultMessage: en[PromptType.categoryNotSaved],
    description: "-",
  },
  [PromptType.categoryInfo]: {
    id: PromptType.categoryInfo,
    defaultMessage: en[PromptType.categoryInfo],
    description: "-",
  },
  [PromptType.campaignCategoryInfo]: {
    id: PromptType.campaignCategoryInfo,
    defaultMessage: en[PromptType.campaignCategoryInfo],
    description: "-",
  },
  [PromptType.deliveryLabel]: {
    id: PromptType.deliveryLabel,
    defaultMessage: en[PromptType.deliveryLabel],
    description: "-",
  },
  [PromptType.datePlaceholder]: {
    id: PromptType.datePlaceholder,
    defaultMessage: en[PromptType.datePlaceholder],
    description: "-",
  },
  [PromptType.timePlaceholder]: {
    id: PromptType.timePlaceholder,
    defaultMessage: en[PromptType.timePlaceholder],
    description: "-",
  },
  [PromptType.welcomePrompt]: {
    id: PromptType.welcomePrompt,
    defaultMessage: en[PromptType.welcomePrompt],
    description: "-",
  },
  [PromptType.randomDelivery]: {
    id: PromptType.randomDelivery,
    defaultMessage: en[PromptType.randomDelivery],
    description: "-",
  },
  [PromptType.specificDate]: {
    id: PromptType.specificDate,
    defaultMessage: en[PromptType.specificDate],
    description: "-",
  },
  [PromptType.library]: {
    id: PromptType.library,
    defaultMessage: en[PromptType.library],
    description: "-",
  },
  [PromptType.promptTitle]: {
    id: PromptType.promptTitle,
    defaultMessage: en[PromptType.promptTitle],
    description: "-",
  },

  [PromptType.promptMessage]: {
    id: PromptType.promptMessage,
    defaultMessage: en[PromptType.promptMessage],
    description: "-",
  },
  [PromptType.videoMessage]: {
    id: PromptType.videoMessage,
    defaultMessage: en[PromptType.videoMessage],
    description: "-",
  },
  [PromptType.videoBtn]: {
    id: PromptType.videoBtn,
    defaultMessage: en[PromptType.videoBtn],
    description: "-",
  },
  [PromptType.attachDesc]: {
    id: PromptType.attachDesc,
    defaultMessage: en[PromptType.attachDesc],
    description: "-",
  },
  [PromptType.messagePlaceholder]: {
    id: PromptType.messagePlaceholder,
    defaultMessage: en[PromptType.messagePlaceholder],
    description: "-",
  },
  [PromptType.titlePlaceholder]: {
    id: PromptType.titlePlaceholder,
    defaultMessage: en[PromptType.titlePlaceholder],
    description: "-",
  },
  [PromptType.uploadFilePlaceholder]: {
    id: PromptType.uploadFilePlaceholder,
    defaultMessage: en[PromptType.uploadFilePlaceholder],
    description: "-",
  },
  [PromptType.uploadVideoPlaceholder]: {
    id: PromptType.uploadVideoPlaceholder,
    defaultMessage: en[PromptType.uploadVideoPlaceholder],
    description: "-",
  },
  [PromptType.uploadBtn]: {
    id: PromptType.uploadBtn,
    defaultMessage: en[PromptType.uploadBtn],
    description: "-",
  },
  [PromptType.browseBtn]: {
    id: PromptType.browseBtn,
    defaultMessage: en[PromptType.browseBtn],
    description: "-",
  },
  [PromptType.saveBtn]: {
    id: PromptType.saveBtn,
    defaultMessage: en[PromptType.saveBtn],
    description: "-",
  },
  [PromptType.cancelBtn]: {
    id: PromptType.cancelBtn,
    defaultMessage: en[PromptType.cancelBtn],
    description: "-",
  },
  [PromptType.enableBtn]: {
    id: PromptType.enableBtn,
    defaultMessage: en[PromptType.enableBtn],
    description: "-",
  },
  [PromptType.categoryPlaceholder]: {
    id: PromptType.categoryPlaceholder,
    defaultMessage: en[PromptType.categoryPlaceholder],
    description: "-",
  },
  [PromptType.addCategoryBtn]: {
    id: PromptType.addCategoryBtn,
    defaultMessage: en[PromptType.addCategoryBtn],
    description: "-",
  },
  [PromptType.categoryTitle]: {
    id: PromptType.categoryTitle,
    defaultMessage: en[PromptType.categoryTitle],
    description: "-",
  },
});
