import styled from "styled-components";

export const Tag = styled.span`
  float: left;
  background: white;
  color: #77b6ea;
  border: 1px dashed #77b6ea;
  border-radius: 10px;
  padding: 5px;
  margin: 0 5px 5px 0;
  letter-spacing: 1px;
  cursor: pointer;
`;

export const TagDelete = styled.span`
  color: #77b6ea;
  background: white;
  font-size: 12px;
  font-weight: bold;
  margin: 5px;
`;

export const Wrapper = styled.div`
  background: transparent;
  font-family: "Open Sans", sans-serif;
  width: 100%;
`;
