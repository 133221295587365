import styled from "styled-components";
import { Grid, Header } from "semantic-ui-react";
import { theme } from "../../../styling/theme";

export const StickersContainer = styled(Grid.Row)({
  display: "flex !important",
  marginTop: "5px",
  padding: "22px 14px 27px 14px !important",
  backgroundColor: `${theme.backgroundColor}`,
});

export const StickerListGrid = styled.div({
  backgroundColor: `${theme.backgroundColor}`,
  display: "flex !important",
  marginTop: "0px !important",
  marginLeft: "0px !important",
  width: "100%",
  padding: "0px !important",
  flexWrap: "wrap",
});

export const PlayerStickerContentGrid = styled(Grid)({
  display: "flex!important",
  marginTop: "0!important",
  marginLeft: "0!important",
  width: "100%",
  flexWrap: "wrap",
  maxWidth: "800px",
  marginBottom: "0 !important",
});

export const StickerHeader = styled(Header)({
  marginBottom: "12px !important",
  color: `${theme.primaryColor} !important`,
  lineHeight: "21.79px",
  textAlign: "left",
  fontSize: "1.315em",
  fontWeight: 700,
  fontFamily: `"Open Sans", sans-serif`,
});
