import * as React from "react";
import { Modal, Button } from "semantic-ui-react";

import "react-image-crop/dist/ReactCrop.css";
import "./styles.scss";
import Moveable from "react-moveable";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { get } from "lodash";
import { StickerServices } from "../../../../../services";
import * as DTO from "../../../../../interfaces";
import { AppContext } from "../../../../../providers";
import { StickerSelector } from "../StickerSelector";
import { AutostickerServices } from "../../../../../services/AutostickerServices";
import { AutostickerConfig, StickerLocation } from "../../../../../interfaces";

interface OwnProps {
  media: DTO.Media | undefined;
  promptId: number;
  storedConfig: DTO.AutostickerConfig | undefined;
  open: boolean;
  handleClose: () => void;
  handleSave: (config: AutostickerConfig) => void;
}

interface StickerPosition {
  left: number;
  top: number;
  width: number;
  height: number;
}

type Props = OwnProps;

const AutostickerModalFC: React.FC<Props> = ({ open, promptId, media, storedConfig, handleClose, handleSave }) => {
  const [loading, setLoading] = useState(false);
  const [stickers, setStickers] = useState<DTO.MediaGroup>();
  const stickerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const movableRef = useRef<Moveable>(null);
  const [naturalWidth, setNaturalWidth] = useState<number>();
  const [naturalHeight, setNaturalHeight] = useState<number>();
  const [defaultStickerId, setDefaultStickerId] = useState<number | undefined>(storedConfig?.defaultSticker?.id);

  const [currentLocation, setCurrentLocation] = useState<StickerLocation | undefined>(storedConfig);
  const [initialPosition, setInitialPosition] = useState<StickerPosition>();

  const scalingFactor = useMemo(() => {
    if (naturalWidth && imageRef?.current?.width) return naturalWidth / imageRef.current.width;
    else return 1;
  }, [naturalWidth, imageRef?.current?.width]);

  useEffect(() => {
    movableRef?.current?.updateRect("Start", true, true);
  }, [initialPosition]);

  useEffect(() => {
    if (storedConfig?.defaultSticker?.id) setDefaultStickerId(storedConfig?.defaultSticker?.id);
  }, [storedConfig?.defaultSticker?.id]);

  useEffect(() => {
    if (storedConfig && scalingFactor != 1) {
      setInitialPosition({
        left: storedConfig.offsetX / scalingFactor,
        top: storedConfig.offsetY / scalingFactor,
        width: storedConfig.stickerWidth / scalingFactor,
        height: storedConfig.stickerHeight / scalingFactor,
      });
    } else {
      setInitialPosition({
        left: 20,
        top: 20,
        width: 100,
        height: 100,
      });
    }
  }, [scalingFactor, storedConfig]);

  const computedHeight = useMemo(() => {
    if (!naturalHeight || !naturalWidth) return "auto";
    return `${(400 * naturalHeight) / naturalWidth}px`;
  }, [naturalWidth, naturalHeight]);

  const { userContext } = useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");

  React.useEffect(() => {
    const loadStickers = async () => {
      const result = await StickerServices.get(coachId);
      setStickers(result.stickers);
    };

    loadStickers();
  }, [coachId]);

  const onComplete = () => {
    handleClose();
  };

  const saveConfig = async () => {
    setLoading(true);
    const savedConfig = await AutostickerServices.save(buildCurrentConfig());
    handleSave(savedConfig);
    setLoading(false);
    onComplete();
  };

  const updateStickerLocation = () => {
    if (stickerRef.current && imageRef.current) {
      const stickerRect = stickerRef.current.getBoundingClientRect();
      const imageRect = imageRef.current.getBoundingClientRect();
      const offsetX = (stickerRect.x - imageRect.x) * (naturalWidth! / imageRect.width);
      const offsetY = (stickerRect.y - imageRect.y) * (naturalHeight! / imageRect.height);
      const stickerWidth = stickerRect.width * (naturalWidth! / imageRect.width);
      const stickerHeight = stickerRect.height * (naturalHeight! / imageRect.height);
      setCurrentLocation({
        offsetX,
        offsetY,
        stickerWidth,
        stickerHeight,
        mediaWidth: naturalWidth,
        mediaHeight: naturalHeight,
      });
    }
  };

  const defaultSticker = useMemo(() => {
    return stickers?.media?.find(m => m.id && m.id == defaultStickerId);
  }, [defaultStickerId, stickers?.media]);

  const buildCurrentConfig = (): DTO.AutostickerConfigRequest => {
    return {
      mediaId: media?.id,
      promptId: promptId,
      defaultStickerId: defaultSticker?.id,
      ...currentLocation!,
    };
  };

  return (
    <Modal closeIcon className={"autostickerModal"} open={open} onClose={onComplete}>
      <Modal.Header>Auto-Sticker</Modal.Header>
      <Modal.Content>
        <div className={"sticker-config-container"}>
          <div
            className={"media-container"}
            style={{
              width: "400px",
              height: computedHeight,
            }}
          >
            <img
              src={media?.uri}
              ref={imageRef}
              alt={"source image"}
              className={`autosticker-media`}
              onLoad={img => {
                setNaturalWidth(img.currentTarget.naturalWidth);
                setNaturalHeight(img.currentTarget.naturalHeight);
              }}
            />

            {initialPosition && (
              <>
                <div
                  ref={stickerRef}
                  className={"sticker-placeholder"}
                  style={{
                    left: initialPosition.left,
                    top: initialPosition.top,
                    width: initialPosition.width,
                    height: initialPosition.height,
                  }}
                />

                <Moveable
                  ref={movableRef}
                  target={stickerRef}
                  draggable={true}
                  resizable={true}
                  onDrag={e => {
                    e.target.style.transform = e.transform;
                    updateStickerLocation();
                  }}
                  onResize={e => {
                    e.target.style.width = `${e.width}px`;
                    e.target.style.height = `${e.height}px`;
                    e.target.style.transform = e.drag.transform;
                    updateStickerLocation();
                  }}
                  bounds={{
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    position: "css",
                  }}
                />
              </>
            )}
          </div>
          <div className={"sticker-config-info"}>
            <div>
              Move and resize the green box over the image. Stickers will be automatically applied before it is posted.
            </div>
            <div className={"default-sticker-section"}>
              <b>Default Sticker (Optional)</b>
              <div>Choose a sticker that will be used when the player doesn't have a sticker of their own.</div>
              <div>
                <StickerSelector selectedStickerId={defaultStickerId} updateSelectedStickerId={setDefaultStickerId} />
              </div>
            </div>
          </div>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button data-elm-id={`autostickerModalCancelBtn`} onClick={onComplete} className={"rounded"} secondary>
          Cancel
        </Button>
        <Button
          data-elm-id={`autostickerModalDoneBtn`}
          onClick={saveConfig}
          className={"rounded"}
          loading={loading}
          primary
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export const AutostickerModal = AutostickerModalFC;
