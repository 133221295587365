import styled from "styled-components";
import { Grid, Card, Button } from "semantic-ui-react";
import { Svg } from "../../../assets";
import { theme } from "../../../styling/theme";

const defaultImage = Svg.PromptPlaceholder;

const thumbnailBg = (thumbnail?: string): string => {
  if (thumbnail) {
    return `#eaeaf0 url("${thumbnail}") no-repeat center top`;
  }
  return `#eaeaf0 url(${defaultImage}) no-repeat center center`;
};

export const StickerGridStyle = styled(Grid.Column)({
  margin: "0 !important",
  padding: "0 !important",
});

export const StickerCardStyle = styled(Card)({
  background: `${theme.lightBackgroundColor} !important`,
  borderRadius: "6.3px !important",
  border: `1px solid ${theme.backgroundColor} !important`,
  boxShadow: `none !important`,
  width: "90px !important",
  height: "90px !important",
  margin: "0 10px 10px 0 !important",
});

export const ThumbnailPreview = styled.div<{ thumbnailurl?: string }>`
  width: 100%;
  height: 100%;
  margin: 0;
  background: ${(props: any) => thumbnailBg(props.thumbnailurl)};
  background-size: ${(props: any) => (props.thumbnailurl ? "cover" : "inherit")};
`;
