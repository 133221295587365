import * as React from "react";
import ReactCrop, { Crop, makeAspectCrop } from "react-image-crop";
import { Modal, Button, Grid } from "semantic-ui-react";

// import "react-image-crop/lib/ReactCrop.scss";
import "react-image-crop/dist/ReactCrop.css";
import "./styles.scss";
import { useEffect, useState } from "react";

interface OwnProps {
  src?: string;
  open: boolean;
  type?: string;

  handleSave?: (file: any, blob: Blob) => void;
  handleClose: () => void;
}

type Props = OwnProps;

const EditImageModalFC: React.FC<Props> = ({ open, src, handleSave, handleClose }) => {
  const [imageRef, setImageRef] = React.useState<HTMLImageElement | undefined>(undefined);

  const [croppedImageUrl, setCroppedImageUrl] = React.useState<unknown | undefined>(undefined);
  const [blob, setBlob] = React.useState<Blob>();

  const [crop, setCrop] = React.useState<Crop>({
    unit: "%",
    width: 200,
    aspect: 16 / 9,
    x: 0,
    y: 0,
    height: 300,
  });

  React.useEffect(() => {
    if (imageRef) imageRef.crossOrigin = "anonymous";
    makeClientCrop(crop);
  }, [imageRef]);

  React.useEffect(() => {
    if (crop) {
      makeClientCrop(crop);
    }
  }, [crop]);

  const getCroppedImg = async (image: any, cropSection: any, fileName: any) => {
    const canvas = document.createElement("canvas");
    const scale = image.naturalWidth / image.width;
    canvas.width = cropSection.width * scale;
    canvas.height = cropSection.height * scale;
    const context = canvas.getContext("2d");

    context!.globalAlpha = 1;
    context!.globalCompositeOperation = "lighter";
    context!.imageSmoothingEnabled = true;
    context!.imageSmoothingQuality = "high";
    // INFO: image.width * scaleX === image.naturalWidth
    // INFO: image.height * scaleY === image.naturalHeight
    context!.drawImage(
      image,
      cropSection.x * scale, // Cx to start the new image
      cropSection.y * scale, // Cy to start the new image
      cropSection.width * scale, // sW -> Width of source image
      cropSection.height * scale, // sH -> Height of source image
      0, // Cx to start to draw into the canva
      0, // Cy to start to draw into the canva
      cropSection.width * scale, // Destination image width to draw,
      cropSection.height * scale // Destination image height to draw,
    );

    return new Promise(resolve => {
      canvas.toBlob(blobThumbnail => {
        if (!blobThumbnail) {
          return;
        }
        blobThumbnail.text = fileName;
        setBlob(blobThumbnail);
        resolve(window.URL.createObjectURL(blobThumbnail));
      }, "image/png");
    });
  };

  const makeClientCrop = async (cropSection: Crop) => {
    if (imageRef && cropSection.width && cropSection.height) {
      const croppedUrl = await getCroppedImg(imageRef, cropSection, "image.png");
      setCroppedImageUrl(croppedUrl);
    } else if (imageRef && crop.width && crop.height) {
      const croppedUrl = await getCroppedImg(imageRef, cropSection, "image.png");
      setCroppedImageUrl(croppedUrl);
    }
  };

  const onCropComplete = (cropSection: Crop) => {
    makeClientCrop(cropSection);
  };

  interface AspectRatioOption {
    label: string;
    aspectX: number;
    aspectY: number;
  }

  const aspectRatioOptions: AspectRatioOption[] = [
    {
      label: "1:1",
      aspectX: 1,
      aspectY: 1,
    },
    {
      label: "4:5",
      aspectX: 4,
      aspectY: 5,
    },
    {
      label: "16:9",
      aspectX: 16,
      aspectY: 9,
    },
    {
      label: "1.91:1",
      aspectX: 1.91,
      aspectY: 1,
    },
  ];

  const [aspectRatio, setAspectRatio] = useState<AspectRatioOption>(aspectRatioOptions[0]);

  useEffect(() => {
    if (!imageRef || !aspectRatio) return;

    const targetRatio = aspectRatio.aspectX / aspectRatio.aspectY;
    const imageRatio = imageRef.width / imageRef.height;

    let width, height;
    if (imageRatio >= targetRatio) {
      height = imageRef.height;
      width = height * targetRatio;
    } else {
      width = imageRef.width;
      height = width / targetRatio;
    }

    // Calculate scaling factor to maintain aspect ratio within image bounds
    const scale = Math.min(1, imageRef.width / width, imageRef.height / height);

    console.log("cropping with", width, height, scale, targetRatio);

    // Apply scaling factor to ensure aspect ratio is preserved
    width *= scale;
    height *= scale;

    setCrop({
      ...crop,
      width: width,
      height: height,
      aspect: targetRatio,
      x: 0,
      y: 0,
    });
  }, [aspectRatio]);

  return (
    <Modal closeIcon className={"previewModal"} open={open} onClose={handleClose}>
      <Modal.Header>
        {aspectRatioOptions.map(option => {
          return (
            <Button
              data-elm-id={`editImageModalCrop${option.label}Btn`}
              key={option.label}
              onClick={() => {
                setAspectRatio(option);
              }}
              className={aspectRatio === option ? "aspectSelected" : "centered"}
            >
              {option.label}
            </Button>
          );
        })}
      </Modal.Header>
      <Modal.Content>
        <Grid>
          <Grid.Row centered={true}>
            {src && (
              <ReactCrop
                src={src}
                // renderComponent={
                //   type === "IMAGE" ? imageComponent : videoComponent
                // }
                crop={crop}
                className={"reactCropContainer"}
                onComplete={onCropComplete}
                onImageLoaded={setImageRef}
                onChange={setCrop}
              />
            )}
          </Grid.Row>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Grid className={"centered mediumPadding"}>
          <Button
            data-elm-id={`editImageModalDoneBtn`}
            onClick={() => {
              if (blob && croppedImageUrl) {
                handleSave!(croppedImageUrl, blob!);
              }
            }}
            className={"primary rounded centered"}
          >
            Done
          </Button>
        </Grid>
      </Modal.Actions>
    </Modal>
  );
};

export const EditImageModal = EditImageModalFC;
