import * as React from "react";
import { Popup, Button, Icon, Menu, SemanticICONS } from "semantic-ui-react";

import "./styles.scss";
import { SemanticShorthandCollection } from "semantic-ui-react/dist/commonjs/generic";
import { MenuItemProps } from "semantic-ui-react/dist/commonjs/collections/Menu/MenuItem";

interface Props {
  iconName?: SemanticICONS;
  items: SemanticShorthandCollection<MenuItemProps>;
  handleAction: (index: number) => void;
}

const ActionListFC: React.FC<Props> = ({ iconName = "list alternate outline", items, handleAction }) => {
  return (
    <Popup
      position={"top right"}
      trigger={
        <Button
          data-elm-id={`MergeActionsMenuBtn`}
          icon
          size="mini"
          onClick={e => {
            e.preventDefault();
          }}
        >
          <Icon name={iconName} />
        </Button>
      }
      flowing
      hoverable
    >
      <Menu
        items={items}
        onItemClick={(e, data) => {
          handleAction(data.index!);
        }}
        vertical
        secondary
      />
    </Popup>
  );
};

export const ActionList = ActionListFC;
