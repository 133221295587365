import * as React from "react";
import { Grid, Message, Loader, Dimmer } from "semantic-ui-react";
import { useIntl } from "react-intl";
import { isEqual } from "lodash";

import { SmallStickerCard, UploadMultipleElements } from "../../components";
import { PlayersServices } from "../../services";
import { mediaElementSticket } from "../../utils";
import * as DTO from "../../interfaces";

import { SettingsType, descriptors } from "./descriptors";
import { InternalGrid } from "../../styling/baseStyle";
import { StickersContainer, StickerListGrid, PlayerStickerContentGrid, StickerHeader } from "./styled";
import { getDownloadURL } from "firebase/storage";

interface OwnProps {
  withWritePermission: boolean;
  playerId: number;
  onChange: (stickerRequest: DTO.PlayerStickerRequest) => void;
  onLoading: (loading: boolean) => void;
}

type Props = OwnProps;

const StickersFC: React.FC<Props> = ({ withWritePermission = false, playerId, onChange, onLoading }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { formatMessage } = useIntl();
  const [stickers, setStickers] = React.useState<DTO.MediaGroup | undefined>(undefined);
  const [stickersDic, setStickersDic] = React.useState<{ [key: string]: DTO.StickerPreview } | undefined>(undefined);
  const [generalErrors, setGeneralErrors] = React.useState<string[]>([]);
  const [initialAutostickerIndex, setInitialAutostickerIndex] = React.useState<number>();
  const [autostickerIndex, setAutostickerIndex] = React.useState<number>();

  React.useEffect(() => {
    setLoading(true);
    const getStickers = async () => {
      const result = await PlayersServices.getPlayerStickers(playerId);

      setInitialAutostickerIndex(result.autostickerIndex);
      setAutostickerIndex(result.autostickerIndex);
      setStickers(result.stickers);
      if (result.stickers) {
        setStickersDic(() => {
          let dicStickers = {};
          result.stickers!!.media!!.forEach((media, id) => {
            dicStickers = {
              ...dicStickers,
              [id]: {
                uid: id,
                loading: false,
                media,
              },
            };
          });

          return {
            ...dicStickers,
          };
        });
      }

      setLoading(false);
    };

    getStickers();
  }, [playerId]);

  React.useEffect(() => {
    if (stickersDic) {
      const imageLoading = Object.values(stickersDic).reduce(
        (accumulator, stickerValue) => stickerValue.loading || accumulator,
        false
      );

      const initialMediaUrls = stickers?.media.map(e => e.uri) || [];

      const finalMediaUrls = Object.values(stickersDic).map(m => m.media?.uri || "") || [];
      if (
        !imageLoading &&
        (!isEqual(initialMediaUrls.sort(), finalMediaUrls.sort()) || autostickerIndex !== initialAutostickerIndex)
      ) {
        prepareRequest();
      }

      setLoading(imageLoading);
    }
  }, [stickersDic, autostickerIndex]);

  React.useEffect(() => {
    onLoading(loading);
  }, [loading]);

  const prepareRequest = () => {
    if (stickersDic) {
      if (Object.values(stickersDic!).length > 100) {
        setGeneralErrors(() => [formatMessage({ ...descriptors[SettingsType.maximunStickersError] })]);
        return;
      }

      const request: DTO.PlayerStickerRequest = {
        playerId,
        stickers: {
          ...stickers,
          media: Object.values(stickersDic).map((dicElm: DTO.StickerPreview, index) => {
            return {
              ...dicElm!!.media,
              sortOrder: index,
            } as DTO.Media;
          }),
          type: "MIX",
        },
        autostickerIndex: autostickerIndex,
      };
      onChange(request);
    }
  };

  const stickerToFile = (file: DTO.SocialFiles): DTO.StickerPreview => {
    file.uploadTask
      ?.then(() => {
        getDownloadURL(file.uploadTask!.snapshot.ref)
          .then(url => {
            setStickersDic(dicStc => {
              if (dicStc && dicStc[file.uid]) {
                return {
                  ...dicStc,
                  [file.uid]: {
                    ...dicStc[file.uid],
                    loadStatus: "SUBMITTED",
                    loading: false,
                    media: mediaElementSticket(url, "png", "IMAGE", Object(dicStc.keys).length),
                  },
                };
              }
              return {
                ...dicStc,
              };
            });
          })
          .catch(_ => {
            setStickersDic(dicStc => {
              if (dicStc && dicStc[file.uid]) {
                return {
                  ...dicStc,
                  [file.uid]: {
                    ...dicStc[file.uid],
                    loading: false,
                    loadStatus: "FAILED",
                  },
                };
              }
              return {
                ...dicStc,
              };
            });
          });
      })
      .catch(_ => {
        setStickersDic(dicStc => {
          if (dicStc && dicStc[file.uid]) {
            return {
              ...dicStc,
              [file.uid]: {
                ...dicStc[file.uid],
                loadStatus: "FAILED",
              },
            };
          }
          return {
            ...dicStc,
          };
        });
      });

    return {
      loading: true,
      uid: file.uid,
      uploadTask: file.uploadTask,
      thumbnail: file.thumbnail,
    };
  };

  const deleteElement = (key: string): void => {
    setStickersDic(surls => {
      if (surls) {
        delete surls[key];
      }

      return {
        ...surls,
      };
    });
  };

  return (
    <PlayerStickerContentGrid
      className={!withWritePermission ? "listManagerContent disabledForm" : "listManagerContent tabContent"}
      columns={1}
    >
      <Grid.Column width={10} className={"noPaddingTop"}>
        <StickerHeader as="h4">{formatMessage({ ...descriptors[SettingsType.title] })}</StickerHeader>
      </Grid.Column>
      <InternalGrid columns={1} className={"noPadding"}>
        {generalErrors.length > 0 && (
          <Grid.Row columns={1}>
            <Message floating error list={generalErrors} />
          </Grid.Row>
        )}
        <StickersContainer columns={1}>
          <Grid.Column width={16}>
            <UploadMultipleElements
              accept={"image/png, image/jpeg"}
              fileName={`customPlayerStickers/${playerId}`}
              nodeName={"sticker_"}
              title={"Or Browse"}
              customMessage={"Drag and drop your stickers here to upload"}
              onChangeImage={file => {
                setStickersDic(cstickers => {
                  return {
                    ...cstickers,
                    [file.uid]: stickerToFile(file),
                  };
                });
              }}
            />
          </Grid.Column>
        </StickersContainer>

        {stickersDic && Object.keys(stickersDic).length > 0 && (
          <StickerListGrid>
            {Object.keys(stickersDic)?.map((key: string) => {
              const dicElemp = stickersDic[key];
              const uri = dicElemp.media ? dicElemp.media.uri : dicElemp.thumbnail;
              return (
                <SmallStickerCard
                  key={key}
                  uri={uri!!}
                  isAutosticker={autostickerIndex !== undefined && dicElemp.media?.sortOrder === autostickerIndex}
                  onDelete={() => deleteElement(key)}
                  onSetAutosticker={() => {
                    setAutostickerIndex(dicElemp.media?.sortOrder);
                  }}
                />
              );
            })}
          </StickerListGrid>
        )}
      </InternalGrid>
      {loading && (
        <Dimmer active inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
      )}
    </PlayerStickerContentGrid>
  );
};

export const PlayerStickers = StickersFC;
