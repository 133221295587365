import React from "react";
import styled from "styled-components";

import { Wrapper } from "./styled";
import { Icon, Label } from "semantic-ui-react";

import "./styles.scss";

interface Props {
  tags: any[];
  tagDeleteIcon?: string;

  wrapperStyle?: string;

  onDelete: (index: number, tag: any) => void;
}

export const CategoriesTags: React.FC<Props> = ({ tags, wrapperStyle, onDelete }) => {
  const [selectedTags, setSelectedTags] = React.useState(tags);

  React.useEffect(() => {
    setSelectedTags(() => tags);
  }, [tags]);

  const removeTag = (index: number): void => {
    onDelete(index, selectedTags.filter(tag => tag.index === index)[0]);
  };

  const renderTags = (): any => {
    if (selectedTags.length === 0) return null;

    return selectedTags.map((tag, index) => (
      <Label key={`cat-tag-${index}`} className={"cat-tag"} basic>
        {tag && tag.displayValue}
        <Icon name={"delete"} onClick={() => removeTag(tag.index)} />
      </Label>
    ));
  };

  const getInputWrapperStyledComponent = (): any => {
    return wrapperStyle
      ? styled(Wrapper)`
          ${wrapperStyle}
        `
      : Wrapper;
  };

  const InputWrapper = getInputWrapperStyledComponent();
  return <InputWrapper>{renderTags()}</InputWrapper>;
};
