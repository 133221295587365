import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Button, Form, Grid, Header, Input, Message } from "semantic-ui-react";
import { get, isEqual } from "lodash";

import { AppContext } from "../../../providers";
import { PlayerStickers, SideBar, TitleSection } from "../../../components";

import {
  WebComponents,
  SubscribedPlayerSearchResponse,
  ShortLinkResponse,
  TagElement,
  CoachSettings,
  PlayerStickerRequest,
  AdminWebSetting,
  WebSettings,
  PricePlanTier,
} from "../../../interfaces";
import { coachWithWritePermission, URLS } from "../../../utils";

import { WhiteContainer, ContentGrid, SocialGrid } from "../../../styling/baseStyle";

import {
  PlayersServices,
  ShortLinksServices,
  SocialCoachSessionService,
  SubscriptionServices,
  HashTagsServices,
  SettingsServices,
} from "../../../services";
import { EditPlayerForm } from "../../FormikForms/EditPlayer";
import { PlayerCampaigns } from "./components/Campaigns";
import { PlayerInfo } from "./components/PlayerInfo";
import { HashTagSet } from "../../../components/Forms/EditPlayer/HashTagSet";

import "./styles.scss";
import useAdminCoachSettings from "../../../components/AppSettingsTab/adminSettingsHook";

type Props = RouteComponentProps;

const EditPlayerFC: React.FC<Props> = ({ history, match: { params } }) => {
  const { userContext } = React.useContext(AppContext);
  const coachId: string = get(userContext, "coach.id", "");

  const { displayAdminSettings } = useAdminCoachSettings(coachId);

  const [generalErrors, setGeneralErrors] = React.useState<string[]>([]);

  const formSubmitRef = React.useRef<HTMLFormElement>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loadingInfo, setLoadingInfo] = React.useState<boolean>(false);
  const [playerEdited, setPlayerEdited] = React.useState<boolean>(false);
  const [shortLink, setShortLink] = React.useState<ShortLinkResponse | undefined>(undefined);

  const [player, setPlayer] = React.useState<SubscribedPlayerSearchResponse | undefined>(undefined);
  const [updatedHashTagsList, setUpdatedHashTagsList] = React.useState<TagElement[] | undefined>(undefined);
  const [hashtagsListError, setHashtagsListError] = React.useState<{ [key: string]: string } | undefined>();

  const [stickerRequest, setStickerRequest] = React.useState<PlayerStickerRequest | undefined>(undefined);
  const [complianceDisclaimer, setComplianceDisclaimer] = React.useState<string | undefined>(undefined);

  const [isActive, setIsActive] = React.useState<boolean>(true);

  const withWritePermission = coachWithWritePermission(WebComponents.PLAYERS, userContext);

  const playerId = params["playerId"];

  const [showHashTagSection, setShowHashTagSection] = React.useState<boolean>(true);
  const [showCustomStickersSection, setShowCustomStickersSection] = React.useState<boolean>(true);
  const [displayComplianceDisclaimerSection, setDisplayComplianceDisclaimerSection] = React.useState<boolean>(true);

  React.useEffect(() => {
    setLoading(true);
    const getSettings = async () => {
      SettingsServices.getAppSettingsByCoachId(coachId)
        .then(coachAppSettings => {
          const playerHashtagSetting =
            coachAppSettings.settings.find(s => s.appSettingId === CoachSettings.PLAYER_HASHTAG)?.status || "ACTIVE";
          setShowHashTagSection(playerHashtagSetting === "ACTIVE");

          const playerStickersSetting =
            coachAppSettings.settings.find(s => s.appSettingId === CoachSettings.PLAYER_CUSTOM_STICKERS)?.status ||
            "ACTIVE";

          const complianceDisclaimerSetting =
            coachAppSettings.settings.find(s => s.appSettingId === CoachSettings.COMPLIANCE_DISCLAIMER)?.status ||
            "ACTIVE";
          setShowCustomStickersSection(playerStickersSetting === "ACTIVE");
          setDisplayComplianceDisclaimerSection(complianceDisclaimerSetting === "ACTIVE");
        })
        .finally(() => setLoading(false));
    };

    getSettings();
  }, [coachId]);

  React.useEffect(() => {
    if (stickerRequest) {
      setPlayerEdited(true);
    }
  }, [stickerRequest]);

  React.useEffect(() => {
    if (showCustomStickersSection) {
      PlayersServices.getPlayerStickers(playerId);
    }
  }, [showCustomStickersSection]);

  React.useEffect(() => {
    const errorList: string[] = [];
    if (hashtagsListError) {
      Object.keys(hashtagsListError).map(key => {
        return (
          hashtagsListError[key] &&
          !errorList.includes(hashtagsListError[key]) &&
          errorList.push(hashtagsListError[key])
        );
      });
    }
    setGeneralErrors(() => errorList);
  }, [hashtagsListError]);

  React.useEffect(() => {
    if (playerId && userContext) {
      reloadPlayer();
    }
  }, [playerId]);

  const reloadPlayer = (): void => {
    setLoading(true);
    setLoadingInfo(true);
    PlayersServices.getPlayer(coachId, playerId)
      .then((response: SubscribedPlayerSearchResponse) => {
        setPlayer(response);
        setComplianceDisclaimer(response.playerAccount.complianceDisclaimer);
        setIsActive(response.pricePlanSubscription!.status !== "CANCELED");
      })
      .catch(alert)
      .finally(() => {
        // TODO: MADE THIS ASYNC
        ShortLinksServices.getByPlayerId(playerId)
          .then(response => {
            setShortLink(response);
          })
          .finally(() => {
            setLoading(false);
            setLoadingInfo(false);
          });
      });
  };

  const updateStickers = (): void => {
    if (stickerRequest) {
      setLoading(true);
      PlayersServices.updatePlayerStickers(playerId, stickerRequest)
        .catch((e: string) => {
          alert(e.substring(0, 40));
        })
        .finally(() => {
          setLoading(false);
          setStickerRequest(undefined);
        });
    }
  };

  const updateComplianceDisclaimer = (): void => {
    if (complianceDisclaimer) {
      setLoading(true);
      PlayersServices.updatePlayerProfile(playerId, {
        complianceDisclaimer,
      })
        .catch((e: string) => {
          alert(e.substring(0, 40));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const updatePlayerEmail = (newEmail: string): void => {
    if (newEmail) {
      setLoading(true);
      PlayersServices.updatePlayerProfile(playerId, {
        email: newEmail,
      })
        .catch((e: string) => {
          const errorMessage = e.substring(0, 40);
          if (errorMessage.includes("Username already taken")) {
            alert("This email address is already in use. Please choose a different one.");
          } else {
            alert(errorMessage);
          }
        })
        .finally(() => {
          setLoading(false);
          reloadPlayer();
        });
    }
  };

  const updateSubscriptionType = (type: PricePlanTier): void => {
    setLoading(true);
    SubscriptionServices.updatePricePlanTier(playerId, coachId, type)
      .catch((e: string) => {
        const errorMessage = e.substring(0, 40);
        alert(errorMessage);
      })
      .finally(() => {
        setLoading(false);
        reloadPlayer();
      });
  };

  const updateSubscriptionTypeWithValidation = (type: PricePlanTier): void => {
    if (type) {
      if (type === PricePlanTier.PLAYER_BASIC) {
        if (window.confirm("Are you sure you want to downgrade this Player to SocialCoach+?")) {
          updateSubscriptionType(type);
        }
      } else {
        updateSubscriptionType(type);
      }
    }
  };
  const goToSubCoachesList = () => {
    setLoading(false);
    history.push(URLS.coach.players);
  };

  const refreshView = () => {
    setLoading(false);
    setPlayerEdited(false);
    alert("Player updated successfully");
  };

  const setErrors = (errorKeywords: string[]): { [key: string]: string } | undefined => {
    let newErrors = {};
    errorKeywords.map((error: string) => {
      newErrors = {
        ...newErrors,
        [error]: error,
      };
    });

    return newErrors;
  };

  const errorHashTagHandler = (keywords: string[]): void => {
    setHashtagsListError(() => setErrors(keywords));
  };

  const updatePlayer = () => {
    // Execute handleSubmit action on create sub coach form
    if (formSubmitRef.current) {
      formSubmitRef!.current!.handleSubmit();
    }

    if (updatedHashTagsList) {
      const toUpdate = updatedHashTagsList.map(hashtag => hashtag.displayValue);
      HashTagsServices.update(playerId, {
        hashtags: toUpdate,
        addHashTagAutomatically: player!!.playerAccount.addHashTagAutomatically,
      });
    }

    if (showCustomStickersSection) {
      updateStickers();
    }

    updateComplianceDisclaimer();
  };

  const cancelSubscription = () => {
    if (window.confirm("Are you sure you want to cancel this Player's subscription?")) {
      setLoading(true);
      SubscriptionServices.cancelSubscription(playerId, userContext!.coach!.id!)
        .then(() => {
          window.confirm("Player’s subscription has been canceled.");
          reloadPlayer();
        })
        .catch(e => {
          alert(`Error cancelling player: ${e}`);
          setLoading(false);
        });
    }
  };

  const reactivateSubscription = () => {
    if (window.confirm("Are you sure you want to reactivate this Player?")) {
      setLoading(true);
      SubscriptionServices.reactivateSubscription(playerId, userContext!.coach!.id!)
        .then(() => {
          window.confirm("This player has been reactivated. They will receive an email shortly.");
          reloadPlayer();
        })
        .catch(e => {
          setLoading(false);
          alert(`Error reactivating player: ${e}`);
        });
    }
  };

  const resetPwd = (): void => {
    if (window.confirm("Are you sure you want to reset this Player’s password?")) {
      setLoading(true);
      SocialCoachSessionService.resetPasswordRequest(player?.playerAccount.email!);
      alert("Success! This Player will receive an email shortly with instructions for resetting their password");
      setLoading(false);
    }
  };

  const customLinkSection = (): JSX.Element => {
    const readyToSave = isActive && playerEdited;
    const readyToReactivate = !isActive && !playerEdited;
    const withMultipleButtons = readyToSave || readyToReactivate;
    return (
      <SocialGrid.Column width={8} floated="right" className="noPaddingLateral">
        <SocialGrid.Column
          width={5}
          floated={withMultipleButtons ? "right" : "left"}
          className={withMultipleButtons ? "buttonLeft" : "buttonRight"}
        >
          <Button
            data-elm-id={`editPlayerBackBtn`}
            fluid
            className={"secondary rounded buttonRight"}
            onClick={goToSubCoachesList}
          >
            Back
          </Button>
        </SocialGrid.Column>
        {withWritePermission && (
          <SocialGrid.Column width={11} floated="left" className={"buttonRight"}>
            {readyToSave && (
              <Button
                type="submit"
                data-elm-id={`editPlayerSaveBtn`}
                loading={loading}
                fluid
                className={"primary rounded buttonRight"}
                onClick={updatePlayer}
              >
                Save
              </Button>
            )}
            {readyToReactivate && (
              <Button
                data-elm-id={`reactivatePlayerBtn`}
                loading={loading}
                fluid
                className={"primary rounded buttonRight"}
                disabled={loading}
                onClick={reactivateSubscription}
              >
                Reactivate Player
              </Button>
            )}
          </SocialGrid.Column>
        )}
      </SocialGrid.Column>
    );
  };

  return (
    <WhiteContainer>
      <SideBar history={history} />

      <ContentGrid className={"listManagerContent"} columns={1} data-elm-id="editPlayerContentGrid">
        <Grid columns={1}>
          <TitleSection
            title={"Players Details"}
            ready={true}
            showNextOption={false}
            titleSize={isActive ? 9 : 6}
            buttonsSize={isActive ? 7 : 10}
            nextButtonSize={4}
            disableButtons={loading}
            showLinkOption={withWritePermission}
            customLinkSection={customLinkSection()}
          />

          <div className="coachBaseContainer">
            {player?.playerAccount && (
              <PlayerInfo
                playerAccount={player?.playerAccount}
                subscriptionType={player?.pricePlanSubscription?.pricePlan.tier}
                verificationDate={player?.playerAccount.verificationDate}
                resetPasswordHandler={resetPwd}
                handleChangeEmailValue={updatePlayerEmail}
                showResetPassword={withWritePermission && isActive}
                handleCancelPlayer={cancelSubscription}
                handleChangeSubscriptionType={updateSubscriptionTypeWithValidation}
                isActivePlayer={isActive}
                scPlusFeatureActive={displayAdminSettings.scPlusTier}
              />
            )}

            <Grid>
              <Grid.Row>
                <Grid.Column className={!withWritePermission || !isActive ? "disabledForm" : ""}>
                  {player && !loadingInfo && (
                    <EditPlayerForm
                      coachId={userContext!.coach!.id}
                      branches={get(player, "branchNames", [])}
                      regions={get(player, "regionNames", [])}
                      states={get(player, "stateCodes", [])}
                      costCenters={get(player, "costCenterNames", [])}
                      playerId={playerId}
                      shortLinkObject={shortLink}
                      formRef={formSubmitRef}
                      setLoading={setLoading}
                      errorHandler={() => setLoading(false)}
                      onChangeHandler={() => setPlayerEdited(true)}
                      successHandler={refreshView}
                    />
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Grid className={"marginBottom"}>
              {generalErrors.length > 0 && (
                <Grid.Row columns={1}>
                  <Message floating error list={generalErrors} />
                </Grid.Row>
              )}
            </Grid>

            <Grid columns={2} className={"customizationSection"}>
              {/* HASH TAGS */}
              {showHashTagSection && (
                // <Grid className={"marginBottom"}>
                <Grid.Column floated="left">
                  <Grid.Row>
                    <HashTagSet
                      title={"Custom Hashtags"}
                      asPlayer={false}
                      playerId={playerId}
                      coachId={userContext!.coach!.id}
                      customTitleClassName={"hashTagSetTitle titleBigSize"}
                      customRowClassName={"noPaddingTop"}
                      onError={errorHashTagHandler}
                      customTagStyle={`background: rgba(61, 174, 245, 0.2);`}
                      onSuccess={updatedHashTags => {
                        if (!isEqual(updatedHashTags, updatedHashTagsList) && updatedHashTagsList !== undefined) {
                          setPlayerEdited(true);
                        }
                        setUpdatedHashTagsList(updatedHashTags);
                      }}
                    />
                  </Grid.Row>

                  {/* COMPLIANCE DISCLAIMER */}
                  {displayComplianceDisclaimerSection && (
                    <Grid.Row className="mediumPaddingBottom complianceSection">
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Header as={"h3"} color="blue" className="playerSectionTitle">
                            Compliance Disclaimer
                          </Header>
                          <Input
                            className={"inputWithLabel"}
                            fluid
                            id={"complianceDisclaimer"}
                            data-elm-id="complianceDisclaimerInput"
                            type={"text"}
                            placeholder={"Compliance Disclaimer"}
                            onChange={e => {
                              setPlayerEdited(true);
                              setComplianceDisclaimer(e.currentTarget.value);
                            }}
                            value={complianceDisclaimer}
                          />
                        </Form.Field>
                      </Form.Group>
                    </Grid.Row>
                  )}

                  <Grid.Row>
                    {" "}
                    <PlayerCampaigns playerId={playerId} isActive={isActive} />
                  </Grid.Row>
                </Grid.Column>
              )}

              {/* CUSTOM STICKERS */}
              {showCustomStickersSection && (
                <Grid.Column>
                  <PlayerStickers
                    withWritePermission={withWritePermission}
                    playerId={playerId}
                    onChange={setStickerRequest}
                    onLoading={setLoading}
                  />
                </Grid.Column>
              )}
            </Grid>
          </div>
        </Grid>
      </ContentGrid>
    </WhiteContainer>
  );
};

export const EditPlayer = withRouter(EditPlayerFC);
