import * as React from "react";

import "react-image-crop/dist/ReactCrop.css";
import "./styles.scss";
import { useContext } from "react";
import { get } from "lodash";
import { StickerServices } from "../../../../../services";
import * as DTO from "../../../../../interfaces";
import { AppContext } from "../../../../../providers";

interface OwnProps {
  selectedStickerId: number | undefined;
  updateSelectedStickerId: (id: number | undefined) => void;
}

type Props = OwnProps;

const StickerSelectorFC: React.FC<Props> = ({ selectedStickerId, updateSelectedStickerId }) => {
  const [stickers, setStickers] = React.useState<DTO.MediaGroup>();

  const { userContext } = useContext(AppContext);
  const coachId = get(userContext, "coach.id", "");

  React.useEffect(() => {
    const loadStickers = async () => {
      const result = await StickerServices.get(coachId);
      setStickers(result.stickers);
    };

    loadStickers();
  }, [coachId]);

  const handleClick = (id: number) => {
    if (id === selectedStickerId) {
      updateSelectedStickerId(undefined);
    } else {
      updateSelectedStickerId(id);
    }
  };

  return (
    <div className={"sticker-selector"}>
      <div className={"sticker-container"}>
        {stickers?.media?.map(sticker => {
          const isSelected = sticker.id && sticker.id === selectedStickerId;
          return (
            <div
              key={"sticker-" + sticker.id}
              className={`sticker ${isSelected ? "selected" : ""}`}
              onClick={() => handleClick(sticker.id)}
            >
              <img src={sticker.uri} alt={"sticker"} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const StickerSelector = StickerSelectorFC;
